var W = $(window),
	D = $(document);
var GMAPSURL = 'https://maps.googleapis.com/maps/api/js?key=';
var GMAPSTYLES = [];

var ard = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path d="M15.4.5c-.8-.7-2.1-.7-2.8 0L7.9 4.8 3.3.5C2.5-.1 1.2 0 .5.7c-.6.7-.6 1.7 0 2.4L7.9 10l7.5-6.9c.7-.6.8-1.7.1-2.5l-.1-.1z"/></svg>';

$('.select-trigger').selectInput();

$('._js-tabs').tabs();

$('._js-replace-svg').replaceSVG();

$('.checkbox').checkbox();
$('.radio').radio();


(function(){	
	var mw = $('.main-wrapper'),
		mwInner = $('.main-wrapper__inner'),
		qnt = 0,
		header = $('.header-mobile');

	W.on('content:fix',function(){
		if (!W.data('fixed')) {
			W.data('fixed',true);
			// mwInner.css('margin-top',-D.scrollTop());

			var topOffset = D.scrollTop();
			W.data('offset',topOffset);
			if (header.is(':visible')) {
				var headerOffset = (topOffset > 155) ? 155 : topOffset;
				topOffset = (topOffset > 155) ? topOffset-155 : 0;
				header.css('margin-top',-headerOffset);
			};
			mwInner.css('margin-top',-topOffset);

			$('html').addClass('_content-fixed');
			D.scrollTop(0);
		};
		qnt++;
		W.data('qnt',qnt);
	}).on('content:unfix',function(){
		qnt--;
		if (qnt <= 0) {
			qnt = 0;
			var t = parseInt(W.data('offset'));
			mwInner.css('margin-top','');
			header.css('margin-top','');
			$('html').removeClass('_content-fixed');
			D.scrollTop(t);
			W.data('fixed',false);
		};
		W.data('qnt',qnt);
	});
})();

var popup = $('<div class="popup popup-hidden"><div class="popup-inner"><div class="popup-inner__top"></div><div class="helper-close popup-close" title="Close"><span></span></div><div class="popup-content-box"></div><div class="popup-inner__bottom"></div></div></div>');
popup.each(function(){
	var isAnimated = false,
		close, inner, contentBox, spacer = $('<div class="popup-spacer"></div>'), cls, options = {};
	var isFast = false;

	function init(e,cnt) {
		popup.appendTo('body');
		popup.off('show',init);
		popup.on('show',show);
		close = popup.find('.popup-close');
		contentBox = popup.find('.popup-content-box');
		inner = popup.find('.popup-inner');
		popup.trigger('show',[cnt]);
		popup.on('mousedown',hide);
		inner.on('mousedown',function(e){
			e.stopPropagation();
		});
		close.on('click',hide);
	};
	function show(e,opts) {
		isFast = false;
		if (!contentBox.is(':empty')) {
			isFast = true;
			hide();
		};
		if ($('html').hasClass('sidebar-show')) {
			$('.sidebar').trigger('hide');
		};
		if (typeof opts === 'string' && $('#'+opts)) {
			options.content = $('#'+opts);
		} else if (typeof opts === 'object') {
			options = opts;
		};
			if (!isAnimated && options.content) {
				isAnimated = true;
				W.trigger('content:fix');
				spacer.insertAfter(options.content);
				cls = options.content.data('class');

				contentBox.append(options.content);
				popup
					.addClass(cls)
					.css({opacity: 0})
					.removeClass('popup-hidden');
				if (!isFast) {
					popup.transit({ opacity: 1},200,function(){
						isAnimated = false;
					});
				} else {
					popup.css({ opacity: 1});
					isAnimated = false;
				}
				popup.find('.input-wrapper__input:eq(0)').focus();
				if (options.onOpen) options.onOpen();
				D.scrollTop(0);
				D.on('keyup.popup',function(e){
					if (e.which == 27) hide();
				});
			};
	};
	function hide(e) {
		if (!isAnimated) {
			isAnimated = true;
			if (options.onBeforeClose) options.onBeforeClose();
			function hideCallback() {
				popup.addClass('popup-hidden');
				if (cls) popup.removeClass(cls);
				W.trigger('content:unfix');
				options.content.insertAfter(spacer);
				if (options.onClose) options.onClose();
				options = {};
				cls = null;
				isAnimated = false;
				// popup.trigger('hide.onclose').off('hide.onclose');
				contentBox.empty();
			};
			if (!isFast) {
				popup
					.transit({ opacity: 0},200,hideCallback);
			} else {
				popup.css({opacity: 0});
				hideCallback();
			}
			D.off('keyup.popup');
		};
	};
	popup.on('show',init);
	popup.on('hide',hide);

	popup.on('click','.popup__ok-btn',hide);

	D.on('click','.popup-trigger-show',function(){
		var c = $(this).data('content');
		if (c && $(c).length) {
			popup.trigger('show',[{
				content: $(c),
				onOpen: function(){
					$('.popup:eq(0)').find('.textInput:eq(0)').focus();
				}
			}]);
			return false;
		};
	});
});

$('._js-callback').on('click',function(){
	popup.trigger('show',[{
		content: $('.popup-callback')
	}]);
	return false;
});

$('._js-go-up').on('click',function(){
	Z.scrollBodyTo(0);
});

(function(){
	var goup = $('<div class="goup noselect"><i class="fa-sharp fa-solid fa-arrow-up"></i></div>').appendTo('body'),
		isShowed = false;
	D.bind('scroll.goup',function(){
		var top = D.scrollTop();
		if (!isShowed && top >= 300) {
			goup.addClass('_on');
			isShowed = true;
		} else if (isShowed && top < 300) {
			goup.removeClass('_on');
			isShowed = false;
		};
	});
	goup.click(function(){
		Z.scrollBodyTo(0);
	});
})();

$(".recommendation-block__tabs-wrapp").on("click", "li:not(.active)", function() {
	$(this).addClass("active").siblings().removeClass("active");
	var parent = $(this).parents('.recommendation-block__tabs');
	var item = parent.find(".recommendation-block__tabs-item").eq($(this).index()).addClass("open_tab");	
	item.siblings(".recommendation-block__tabs-item").removeClass("open_tab");
});
$(".recommendation-block__tabs-items div:first-child").addClass("open_tab");