// $('.locations__list').each(function(){
// 	var list = $(this),
// 		items = list.find('.locations__item');

// 	$.getScript(GMAPSURL,function(){
// 		init();
// 	});

// 	function init() {
// 		items.each(function(){
// 			var item = $(this);
// 			var coords = item.data('coords').split(',');
// 			coords = {
// 				lat: coords[0]*1,
// 				lng: coords[1]*1
// 			};
// 			zoom = (parseInt(item.data('zoom'))) ? parseInt(item.data('zoom')) : 17;
			
// 			map = new google.maps.Map(item.find('.locations__item-map-container')[0], {
// 				center: coords,
// 				zoom: zoom,
// 				disableDefaultUI: true,
// 				zoomControl: true,
// 				zoomControlOptions: {
// 					position: google.maps.ControlPosition.TOP_RIGHT
// 				},
// 				scrollwheel: false,
// 				styles: GMAPSTYLES
// 			});
// 			var marker = new google.maps.Marker({
// 				position: coords,
// 				map: map
// 			});

// 			// function resizeMap() {
// 			// 	var center = map.getCenter();
// 			// 	google.maps.event.trigger(map, "resize");
// 			// 	map.setCenter(center);
// 			// };
// 			// google.maps.event.addDomListener(window, "resize", function() {
// 			// 	resizeMap();
// 			// });
// 			// W.bind('resize.map',resizeMap);
// 		});
// 	};
// });

$('.locations__item-tour').each(function(){
	var t = $(this);

	t.find('.locations__item-tour-link').on('click',function(){
		$(this).hide();
		t.find('.locations__item-tour-box').show();
		return false;
	});
});

$(document).on('click', '.locations__quote,.header__extra-nav-link.quote,.product__buy .quote,p.catalogue__item-cart a.quote,.compare-list__item-buy .quote,.wishlist__item-actions .quote, .special-sale__cta .quote,#RequestQuote,.header__quote.quote', function () {
    $('#PRODUCTID').val($(this).data('model-number'));
    $('.mc_embed_signup_scroll').fadeIn();
    $('.blackblock').fadeIn();
});

$('.mc_embed_signup_close').click(function(){
	$('.mc_embed_signup_scroll').fadeOut();
	$('.blackblock').hide();
});

$(document).keydown(function(e) {
    if( e.keyCode === 27 ) {
        $('.mc_embed_signup_close').click();
        return false;
    }
});

$( "body" ).on( "click", ".blackblock", function() {
	$('.mc_embed_signup_scroll').fadeOut();
  $( this ).hide();
});