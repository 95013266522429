$('.compare-list').each(function(){
	var cl = $(this),
		items = cl.find('.compare-list__item'),
		blocks = items.eq(0).find('.compare-list__item-block').length;


	/*function resize() {
		items.each(function(){
			var item = $(this);
			var bs = item.find('.compare-list__item-block');
			item.data('b',[]);
			bs.each(function(index){
				var b = $(this);
				b.css('min-height','');
				item.data('b')[index] = b.height();
			});
			// console.log(item.data('b'))
		});
		var max = [];
		items.each(function(){
			var b = $(this).data('b');
			for (var i = 0; i < b.length; i++) {
				if (!max[i] || b[i] > max[i]) {
					max[i] = b[i];
				};
			};
		});
		// console.log(max);
		items.each(function(){
			var item = $(this);
			var bs = item.find('.compare-list__item-block');
			bs.each(function(index){
				$(this).css('min-height',max[index]);
			});
		});
	};
	resize();
	W.on('resize.compare',resize);

	if (document.fonts) {
		document.fonts.ready.then(resize);
	};*/


	if (items.length > 1) {
		// cl.find('.swiper-container').append('<div class="swiper-scrollbar"></div>');

		var swiper = new Swiper(cl.find('.swiper-container')[0],{
			speed: 400,
			slidesPerView: 'auto'
		});
	};
});

function equalHeight(group) {
    var tallest = 0;
    group.height('auto');
    group.each(function () {
        var thisHeight = $(this).height();
        if (thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.height(tallest);
}
function initEqualHeight() {
    equalHeight($(".compare-list__item-heading"));
    equalHeight($(".compare-list__item-model"));
    equalHeight($(".compare-list__item-block-colors"));
    equalHeight($(".compare-list__item-block-description"));
    equalHeight($(".compare-list__item-block-dimensions"));
	equalHeight($(".compare-list__item-blocks-top"));
	equalHeight($(".compare-list__btns"));
}
$(window).resize(initEqualHeight);
initEqualHeight();

var checkInterval = setInterval(checkLoadPrice, 500);
function checkLoadPrice() {
	var priceLoaded = true;
	$('.compare-list__item-price').each(function(){
		if ($(this).is(':empty')) {
			priceLoaded = false;
		}
	});
	if (priceLoaded) {
		clearInterval(checkInterval);
		initEqualHeight();
	}
}

$('.header__section-ul li').on("mouseover",function(){
	$('.header__section-ul li,.header__menublock-wrapper,.header__menu-image-item').removeClass('-active');
	var index = $(this).addClass('-active').index();
	$('.header__menublock-wrapper').eq(index).addClass('-active');
	$('.header__menu-image-item').eq(index).addClass('-active');
});

$(document).on('click', '.side-nav__nav-link', function(){
	$(this).toggleClass('-active');
	$(this).siblings('.side-nav__nav-sublist').slideToggle();
});

$(document).on('click', '.side-nav__nav-sublink', function(){
	var closing = $(this).hasClass('-active');
	
	$(".side-nav__nav-sublink.-active").removeClass("-active");
	$(".side-nav__nav-sublink:not(.-active)").each(function (){
		$(this).siblings('.header__menu-segmentw').slideUp();
	});

	if(!closing) {
		$(this).toggleClass('-active');
		$(this).siblings('.header__menublock-wrapper').slideToggle();
		$(this).siblings('.header__menu-segmentw').slideToggle({complete: function(){
			// When -active the menu grows and top doesn't account for that ?
			var growthCompensation = 117;
			$('.side-nav ').animate({scrollTop: $(this).parent().position().top+growthCompensation}, 1000);			
		}});
	}	
});

$(".side-nav__nav-sublink.-active").siblings('.header__menu-segmentw').slideToggle();